import { useEffect, useCallback, useState } from 'react';

import { Box, Grid, Button, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import { SelectField, TextField, DateTimePicker, MultilineField, Dialog } from 'style-guide';

import { STATUS } from 'constants/global-constants';
import { REQUEST_ACTIONS, sendRequest, setOrderData } from 'utils/Communicator';

import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from 'reducers/order-reducers/ordersSlicer';
import { filterServiceProviderImporter } from 'Utils';

import { snackbarToggle } from 'reducers/snackbarSlicer';
import { addOrderFilter, addSearchFilter, addStatusChatMessage, getStatusChatMessage } from 'Utils';
import { onTruckingCompanyNameOptionsChange, onTruckingCompaniesChange } from 'reducers/TruckingCompanySlicer';
const ImporterDialog = ({ loginUser, order, openDialog, setOpenDialog, pageName, onHandleCloseDialog1 }) => {
    const dispatch = useDispatch();
    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const [approveFlag, setApproveFlag] = useState(order.airStatus?.importerExit);
    const [customsDate, setCustomsDate] = useState(order.airStatus?.importerDate);
    const [deliveryDate, setDeliveryDate] = useState(order.deliveryDate);
    const [truckingCompany, setTruckingCompany] = useState(order.importerTruck);
    const onHandleCheckboxChange = (event, action) => dispatch(action(event.target.checked));
    const onHandleChange = (event, action) => dispatch(action(event.target.value));
    const { truckingCompanyNameOptions } = useSelector(state => state.truckingCompanies);
    const {
        userLocalNumber,
        userAbroadNumber,
        urgentShipment,
        consigneeId,
        customerId,
        shipperId,
        countryOfOriginId, originState, fileNumber,
        shippingModeId,
        subModeId,
        userLocalId, userTracer, userCustoms,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.orderTableFilter);
    const { page, size } = useSelector(state => state.orders);

    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const onHandleCloseDialog = () => {
        setOpenDialog(false);
    };
    const { truckingCompanies } = useSelector(state => state.truckingCompanies);

    useEffect(() => {
        const truckingCompanyNameOptions = truckingCompanies.map(company => {
            return { id: company.id, name: company.companyName, serviceProviderLocationId: company.serviceProviderLocationId, };
        });

        dispatch(onTruckingCompanyNameOptionsChange(truckingCompanyNameOptions));
    }, [truckingCompanies, dispatch]);
    useEffect(() => {
        if (!truckingCompanies || truckingCompanies.length <= 0) {
            sendRequest('/api/serviceProvider/3?activeFlag=true', REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    dispatch(onTruckingCompaniesChange(response));
                    dispatch(onTruckingCompanyNameOptionsChange({ id: response.id, name: response.companyName, serviceProviderLocationId: response.serviceProviderLocationId, }));
                },
                failedCallback,
            });
        }
    }, [failedCallback, dispatch, truckingCompanies]);

    useEffect(() => {
        // predefined user abroad data get
        if (!nlUsers || nlUsers == undefined) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setNlUsers(response); },
                failedCallback,
            });
        }
    }, [nlUsers, failedCallback, setNlUsers]);

    useEffect(() => {
        // predefined user abroad data get
        if (!usUsers || usUsers == undefined) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setUSUsers(response); },
                failedCallback,
            });
        }
    }, [usUsers, failedCallback, setUSUsers]);

    useEffect(() => {
        // predefined user local data get
        if (!ilUsers || ilUsers == undefined) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setIlUsers(response); },
                failedCallback,
            });
        }
    }, [ilUsers, failedCallback, setIlUsers]);


    const saveCustomsData = () => {
        if (order.shippingModeId == 1) {
            sendRequest(`/api/fa/setImporterExitAir/${order.id}/${approveFlag}/${truckingCompany}/${customsDate}/${deliveryDate}`, REQUEST_ACTIONS.GET, null, {
                successCallback: data => {
                    //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                    sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                        successCallback: response => { },
                        failedCallback,
                    });
                    sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                        successCallback: response => {
                            setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                            setOpenDialog(false);
                        },
                        failedCallback,
                    });
                },
                failedCallback,
            });
        } else {
            sendRequest(`/api/fa/setImporterExitSea/${order.id}/${approveFlag}/${truckingCompany}/${customsDate}/${deliveryDate}`, REQUEST_ACTIONS.GET, null, {
                successCallback: data => {
                    //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                    sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                        successCallback: response => {
                            sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                                successCallback: response => {
                                    setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                                    setOpenDialog(false);
                                },
                                failedCallback,
                            });
                        },
                        failedCallback,
                    });
                },
                failedCallback,
            });
        }
    }

    const getTruckingOptions = () => {
        var options = filterServiceProviderImporter(2, truckingCompanyNameOptions)
        return options
    }
    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/order/updateChat/${id}?fromStage=${fromStage}&loginUserId=` + loginUser?.id + `&actionOn=DepartureDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                        onHandleCloseDialog();
                    },
                    failedCallback,
                });
            },
            failedCallback,
        });
    };
    return (
        <Dialog maxWidth='md' open={openDialog} onHandleCancel={onHandleCloseDialog} title={'Importer - ' + order?.jobNumber}>

            {order.shippingModeId != 1 && <Grid item container flexWrap="nowrap">
                {
                    <Grid container spacing={2} style={{marginBottom: "20px"}}>
                        {order.taskyam?.data?.ContainerInvalidForTooLong &&
                            <Grid item container>Container has not been returned for more than 7 days.</Grid>}
                    </Grid>
                }
            </Grid>}
            <Grid item container flexWrap="nowrap">
                {<Grid container spacing={2} flexDirection="column" flexWrap="nowrap">
                    {order.orderStatus[7].status === "SUCCESS" && <view style={{ marginLeft: 15 }}>
                        <FormControlLabel
                            control={
                                <Checkbox disabled={order.orderStatus[7].status !== "SUCCESS"} checked={approveFlag} onChange={event => setApproveFlag(!approveFlag)} />
                            }
                            label='Importer Delivered'
                        />
                    </view>}
                    <DateTimePicker
                        required
                        label='Importer Delivered Time'
                        value={customsDate}
                        onChange={date => setCustomsDate(date)}
                    />
                    <DateTimePicker
                        required
                        label='Delivery date'
                        value={deliveryDate}
                        onChange={date => setDeliveryDate(date)}
                    />

                    <SelectField
                        lg={12}
                        label='Trucking CO'
                        options={getTruckingOptions()}
                        value={truckingCompany}
                        onChange={event => setTruckingCompany(event.target.value)}
                    />
                    {false && <Button
                        dissabled={!approveFlag}
                        variant='contained'
                        style={{ margin: "15px", maxWidth: "150px" }}
                        onClick={saveCustomsData}>
                        Save
                    </Button>}
                    {<Grid container spacing={2} flexDirection="row" flexWrap="nowrap" style={{ marginTop: "10px" }} justifyContent="flex-end">
                        <Button
                            variant='contained'
                            style={{ margin: "15px", maxWidth: "150px" }}
                            onClick={saveCustomsData}>
                            Save
                        </Button>
                        <Button
                            variant='contained'
                            style={{ margin: "15px", maxWidth: "150px" }}
                            onClick={onHandleCloseDialog1}>
                            Cancel
                        </Button>
                    </Grid>}
                </Grid>}
            </Grid>
        </Dialog>
    );
};

export default ImporterDialog;
