import { useState, memo } from 'react';

import StageButton from '../StageButton';
import ImporterDialog from './ImporterDialog';
import {StyledTableCell} from 'Utils';

const Importer = ({ loginUser, order }) => {
    const getStatus = () => {
        var stage = { 'status': `${order?.orderStatus ? (order?.orderStatus[8]?.status ? order.orderStatus[8]?.status : `${order?.orderStatus[7]?.status === "SUCCESS" ? "IN_PROGRESS" : "NOT_STARTED"}`) : null}`, 'notes': "" };
        if (order.locationId != 2) {
            if (order.incotermsId == 3) {
                stage = {};
                stage.status = "dissable";
                stage.notes = "";
            }
        } else {
            if (order.incotermsId == 3 || order.incotermsId == 7 || order.incotermsId == 9 || order.incotermsId == 9) {
                stage = {};
                stage.status = "dissable";
                stage.notes = "";
            }
        }
        return stage;
    }
    const currentStage = order.orderStatus ? order.orderStatus[7] : null;

    const [openDialog, setOpenDialog] = useState(false);
    const onHandleOpenDialog = () => setOpenDialog(true);
    const onHandleCloseDialog = () => setOpenDialog(false);

  return (
      <StyledTableCell style={{ width: "5vw", p: 0 }}>
          <StageButton stage={getStatus()} onHandleOpenDialog={onHandleOpenDialog} stageName='Import' loginUser={loginUser}/>
          {openDialog && <ImporterDialog loginUser={loginUser} order={order} openDialog={openDialog} setOpenDialog={setOpenDialog} onHandleCloseDialog1={onHandleCloseDialog} />}
    </StyledTableCell>
  );
};

export default memo(Importer);
